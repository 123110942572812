import React, { useState } from 'react'
import { Animate } from '@islandsstofa/react'

type Props = {
  textArray: { html: string }[]
  videos: {
    url: string
  }[]
}

const HeroTopVideo: React.FC<Props> = ({ textArray, videos }) => {
  const INITIAL_ANIM_DELAY = 750
  const STAGGER = 200

  const [currentVideoIndex, setCurrentVideoIndex] = useState(
    Math.floor(Math.random() * videos.length)
  )

  return (
    <div className='hero-top-video page-slice-spacer position-relative'>
      <video
        muted
        autoPlay
        playsInline
        onEnded={() =>
          setCurrentVideoIndex(prevIndex =>
            prevIndex === videos.length - 1 ? 0 : prevIndex + 1
          )
        }
        src={videos[currentVideoIndex].url}
      />
      <div className='hero-top-video__text-container px-2 pl-lg-0'>
        {textArray.map((text, i) => (
          <Animate
            key={i}
            type='slide-up'
            delay={INITIAL_ANIM_DELAY + i * STAGGER}
            duration={500}
          >
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
          </Animate>
        ))}
      </div>
    </div>
  )
}

export default HeroTopVideo
