import System from '@cmp/sections/System'
import Staff from '@cmp/sections/Staff'
import BoardList from '@cmp/sections/BoardList'
import HeroCinematic from '@cmp/sections/HeroCinematic'
import {
  Feature,
  RichText,
  HeroEpic,
  HeroMedia,
  HeroMediaType,
  InformationBanner,
  SlimFeature,
  ThreeImages,
  SEO,
  SEOProps,
  ImageGrid,
  ImageGridInterface,
  ThreeImagesProps
} from '@islandsstofa/react'
import HeroHeader from '@cmp/sections/IcelandicHeroHeader'
import HeroTopVideo from '@cmp/sections/HeroTopVideo'
import { gsap } from 'gsap'
import React from 'react'
import Img from 'gatsby-image'
import CardGrid, { CardType } from '@cmp/site/CardGrid'
import { handleImgixUrl } from '@kogk/common'

export default {
  PrismicPageBodyHeroTopVideo: {
    component: HeroTopVideo,
    dataToProps(data: any) {
      return {
        videos: data.items.map((item: any) => ({ url: item.video.url })),
        textArray: [data.primary.title, data.primary.text]
      }
    }
  },

  PrismicPageBodySeo: {
    component: SEO,
    dataToProps: (data: any): SEOProps => ({
      title: data.primary.override_title,
      description: data.primary.override_description,
      image: data.primary.override_image,
      keywords: data.primary.override_keywords
    })
  },

  PrismicPageBodyImageGrid: {
    component: ImageGrid,
    dataToProps(data: any) {
      const result: ImageGridInterface = {
        headline: data.primary.headline.text,
        elements: data.items.map((item: any) => ({
          logo: item.logo,
          name: item.name,
          url: item.website_url.url
        }))
      }
      return { ...result }
    }
  },

  PrismicPageBodyThreeCards: {
    component: CardGrid,
    dataToProps(data: any) {
      const cards: CardType[] = data.items.map((item: any) => ({
        image: item.image,
        text: item.text,
        link: { ...item.link, label: item.link_label }
      }))

      return { cards }
    }
  },

  PrismicPageBodyThreeImages: {
    component: ThreeImages,
    dataToProps(data: any): ThreeImagesProps {
      const images = [
        data.primary.first_image,
        data.primary.second_image,
        data.primary.third_image
      ]
      return {
        images: images.filter(image => image.url),
        onRenderImage: image => (
          <Img
            fluid={handleImgixUrl(image.url).gatsbyImageFluid()}
            alt={image.alt}
          />
        )
      }
    }
  },

  PrismicPageBodyHeroCinematic: {
    component: HeroCinematic,
    dataToProps(data: any) {
      return {
        headline: data.primary.headline,
        gradientOverlay: data.primary.gradient_overlay,
        text: data.primary.text,
        videos: data.items.map((v: any) => v.video)
      }
    }
  },

  PrismicPageBodyInformationBanner: {
    component: InformationBanner,
    dataToProps(data: any) {
      const items = data.items.map((item: any) => {
        return {
          title: item.group_name,
          statistics: [
            { title: item.statistic_one_name, value: item.statistic_one_value },
            { title: item.statistic_two_name, value: item.statistic_two_value }
          ]
        }
      })

      return { items }
    }
  },

  PrismicPageBodyBoardList: {
    component: BoardList,
    dataToProps(data: any) {
      return {
        title: data.primary.boardTitle,
        boardMembers: data.items
      }
    }
  },

  PrismicPageBodyStaff: {
    component: Staff,
    dataToProps(data: any) {
      return {
        staffMembers: data.items
      }
    }
  },

  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps(data: any) {
      return {
        html: data.primary.rich_text.html
      }
    }
  },

  PrismicPageBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage,
          see_gallery_message: btnLabel
        },
        items: gallery
      } = data
      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        btnLabel,
        onRenderImageComponent: () => (
          <Img
            className='image-rounded'
            fluid={singleImage.fluid}
            alt={singleImage.alt}
          />
        )
      }
    }
  },

  PrismicPageBodyHeroEpic: {
    component: HeroEpic,
    dataToProps(data: any) {
      const {
        primary: { image, title, text, video, link_text, link_to_page }
      } = data

      return {
        title: title.text,
        text: text.text,
        image,
        video,
        link: {
          text: link_text,
          url: link_to_page?.url
        },
        gsap
      }
    }
  },

  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          image_on_right: imageOnRight
        }
      } = data

      return {
        html,
        image,
        imageOnRight
      }
    }
  },

  PrismicPageBodySlimFeature: {
    component: SlimFeature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          link_text,
          link_to_page
        }
      } = data
      return {
        html,
        image: {
          alt: image?.alt,
          url: image?.fluid.src
        },
        imageTag: link_to_page?.document.tags[0],
        link: {
          text: link_text,
          url: link_to_page?.url
        }
      }
    }
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data: any) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(
            ({ parameter, value }: { parameter: string; value: string }) =>
              parameter && value !== null
          )
          .reduce((acc: any, item: any) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  },

  PrismicPageBodyHeroHeader: {
    component: HeroHeader,
    dataToProps(data: any) {
      return {
        title: data.primary.title.text,
        text: data.primary.text,
        link: {
          url: data.primary.link.url,
          label: data.primary.link_label
        },
        video: data.primary.video,
        image: data.primary.video
      }
    }
  }
}
