import { useEffect } from 'react'

const useSetTheme = (tags: string[]) => {
  useEffect(() => {
    if (document) {
      if (tags.includes('DEFAULT_FRONTPAGE')) {
        document.body.className = 'blue-theme'
      } else {
        document.body.className = ''
      }
    }
  }, [])
}

export default useSetTheme
