import { FluidObject } from 'gatsby-image'
import React from 'react'
import { BasicCard } from '@islandsstofa/react'
import Img from 'gatsby-image'
import { handleImgixUrl } from '@kogk/common'
import { Link } from 'gatsby'

export type CardType = {
  text: {
    html: string
  }
  image: {
    url: string
    alt: string
    fluid: FluidObject
  }
  link: {
    url: string
    type: string
    label: string
  }
}

const CardGrid = ({ cards }: { cards: CardType[] }) => {
  const LinkWrap: React.FC<{ link: { url: string; type: string } }> = ({
    link,
    children
  }) =>
    link.type === 'page' ? (
      <Link to={link.url}>{children}</Link>
    ) : (
      <a href={link.url} target='_blank'>
        {children}
      </a>
    )

  return (
    <div className='card-grid container page-slice-spacer'>
      {cards.map((card, key) => (
        <LinkWrap key={key} link={card.link}>
          <BasicCard
            variant='extra-tall'
            externalLinkIcon={card.link.type !== 'page'}
            onRenderFootnote={() => (
              <p className='input-label'>{card.link.label}</p>
            )}
            onRenderImage={() => (
              <Img
                fluid={handleImgixUrl(card.image.url).gatsbyImageFluid()}
                alt={card.image.alt}
                className='image-rounded'
              />
            )}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: card.text.html
              }}
            />
          </BasicCard>
        </LinkWrap>
      ))}
    </div>
  )
}

export default CardGrid
