import React from 'react'
import { Animate, ButtonDep as Button } from '@islandsstofa/react'
import { Link } from 'gatsby'

type Props = {
  title: string
  text: {
    html: string
  }
  link: {
    url: string
    label: string
  }
  image: {
    url: string
    alt: string
  }
  video: {
    url: string
  }
}

const IcelandicHeroHeader = ({ title, text, link, image, video }: Props) => {
  return (
    <div className='hero-header page-slice-spacer'>
      <div>
        <video
          className='hero-header__video'
          src={video.url}
          autoPlay
          muted
          loop
          playsInline
          placeholder={image.url}
        />
      </div>
      <div className='hero-header__content col-lg-8 pt-3 pt-lg-5 pt-xl-6 offset-lg-2'>
        <Animate type='slide-up' delay={200}>
          <h1>{title}</h1>
        </Animate>
        <Animate type='slide-up' delay={700}>
          <div
            dangerouslySetInnerHTML={{ __html: text.html }}
            className='article'
          />
        </Animate>
        <Animate type='slide-up' delay={900}>
          <Link to={link.url}>
            <Button
              className='mt-2 mt-lg-3'
              type='primary'
              label={link.label}
            ></Button>
          </Link>
        </Animate>
      </div>
    </div>
  )
}

export default IcelandicHeroHeader
