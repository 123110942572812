import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import MainLayout from '@cmp/layout/MainLayout/MainLayout'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import useSetTheme from '@root/src/hooks/useSetTheme'
import Footer from '@cmp/site/Footer'

const Slices = makeSlices(sliceMapping)

type PageProps = {
  data: {
    staticData: any
  }
  location: {
    pathname: string
  }
}

const Page = ({ data: staticData, location }: PageProps) => {
  const data = usePreview(staticData)

  const language = data.prismicPage.lang.substring(0, 2)

  const altUrls = [
    ...data.prismicPage.alternate_languages.map(
      ({ document }: { document: any }) => document
    ),
    ...(location
      ? [
          {
            url: location.pathname,
            lang: language
          }
        ]
      : [])
  ]

  useSetTheme(data.prismicPage.tags ?? '')

  return (
    <GlobalDataProvider language={language} altUrls={altUrls}>
      <MainLayout>
        <article className='page-wrap'>
          {data.prismicPage.data && (
            <Slices body={data.prismicPage.data.body} />
          )}
        </article>
        <Footer lang={language} />
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
