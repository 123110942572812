import React, { useEffect, useState } from 'react'
import { handleImgixUrl } from '@kogk/common'
import { AspectRatioBox, Animate } from '@islandsstofa/react'
import { pseudoRandom } from '@src/utils'

type Props = {
  image: {
    url: string
    alt: string
  }
  headline: { text: string }
  gradientOverlay: boolean
  text: { html: string }
  videos: {
    url?: string
  }[]
  fullTrailer?: {
    url: string
  }
}

const Headline: React.FC = ({ children }) => (
  <div className='container pt-6 pt-lg-4 position-relative h-100'>
    {children}
  </div>
)

const HeroCinematic: React.FC<Props> = ({
  image,
  headline,
  gradientOverlay,
  text,
  videos
}) => {
  const imageSrc = image ? handleImgixUrl(image.url).url() : null
  const [currVideo, setCurrVideo] = useState(0)
  const [shuffledVideos, setShuffledVideos] = useState<{ url?: string }[]>(
    videos
  )

  const [bg, setBg] = useState(`url(${imageSrc})`)

  useEffect(() => {
    setShuffledVideos(
      pseudoRandom(videos, (a, b) =>
        a.url && b.url ? b.url.length - a.url.length : 0
      )
    )
    if (gradientOverlay) {
      setBg(
        `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 83.33%, #0D0D0D 100%), url(${imageSrc})`
      )
    }
  }, [])

  const updateSrc = () => {
    // This way videos will loop based on total number of videos
    setCurrVideo((currVideo + 1) % videos.length)
  }

  if (shuffledVideos.length > 1) {
    return (
      <div className='hero-cinematic page-slice-spacer'>
        <div className='hero-cinematic__video-container'>
          <AspectRatioBox ratio='widescreen'>
            {shuffledVideos[currVideo].url && (
              <Animate type='fade-in'>
                <video
                  className='hero-cinematic__video'
                  autoPlay
                  muted
                  onEnded={() => updateSrc()}
                  src={shuffledVideos[currVideo].url}
                  playsInline
                />
              </Animate>
            )}
          </AspectRatioBox>
        </div>
        <Headline>
          {headline && (
            <Animate type='slide-up' delay={1000}>
              <h2 className='text-center'>{headline.text}</h2>
            </Animate>
          )}
          {text && (
            <Animate type='slide-up' delay={1250}>
              <div
                className='article text-center'
                dangerouslySetInnerHTML={{ __html: text.html }}
              />
            </Animate>
          )}
        </Headline>
      </div>
    )
  } else {
    return (
      <div
        style={{
          backgroundImage: bg
        }}
        className='hero-cinematic page-slice-spacer'
      >
        <Headline>
          {headline && <h1>{headline.text}</h1>}
          {text && (
            <div
              className='article'
              dangerouslySetInnerHTML={{ __html: text.html }}
            />
          )}
        </Headline>
      </div>
    )
  }
}

export default HeroCinematic
