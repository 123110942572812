import React from 'react'
import { useGetFooter } from '@src/hooks'
import { FooterUi, IconType } from '@islandsstofa/react'

type Props = {
  lang: string
}

const Footer = ({ lang }: Props) => {
  const d: { logoType: IconType; [propName: string]: any } = {
    logoType: 'icelandicLogo',
    en: lang.slice(0, 2) === 'en',
    ...useGetFooter(lang)
  }
  if (!d) {
    return null
  }

  return (
    <FooterUi
      onPostlistRender={() => (
        <div className='mt-n2'>
          <a
            href='https://analytics-eu.clickdimensions.com/cn/azxai/icelandic'
            target='_blank'
            className='button--secondary footer__contact-us-btn'
          >
            {lang === 'is' ? 'Hafðu samband' : 'Contact us'}
          </a>
        </div>
      )}
      {...d}
    />
  )
}

export default Footer
